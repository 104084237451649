<template>
	<div class="notice">
		<div style="display: flex;justify-content: space-between;">
			<div class="header">
				<div>赛事管理></div>
				<div class="current">最高纪录</div>
			</div>
		</div>
		<div class="game">
			<p>北京体育运动最高纪录</p>
			<img src="../assets/image/game1.png" alt="">
		</div>

		<div class="searchfor">
			<el-select placeholder="请选择" class="project" filterable v-model="info.projectName" clearable @change="getxm($event,1)">
				<el-option v-for="item in options1" :key="item.projectName" :label="item.projectName"
					:value="item.projectName">
				</el-option>
				<template slot="prefix">
					<div style="display: flex;">
						<span class="searchforlogo">
							<img src="../assets/image/project2.png" alt="" srcset="">
						</span>
						<span class="titlesearc">项目：</span>
					</div>
				</template>
			</el-select>
			<el-select placeholder="请选择" class="area" v-model="info.groupName" clearable @change="getxm($event,3)">
				<el-option v-for="item in options2" :key="item.groupName" :label="item.groupName" :value="item.groupName">
				</el-option>
				<template slot="prefix">
					<div style="display: flex;">
						<span class="searchforlogo">
							<img src="../assets/image/group.png" alt="" srcset="">
						</span>
						<span class="titlesearc">组别：</span>
					</div>
				</template>
			</el-select>
			<el-select placeholder="请选择" class="level" v-model="info.subProject" clearable @change="getxm($event,2)">
				<el-option v-for="item in options3" :key="item" :label="item" :value="item">
				</el-option>
				<template slot="prefix">
					<div style="display: flex;">
						<span class="searchforlogo">
							<img src="../assets/image/Event.png" alt="" srcset="">
						</span>
						<span class="titlesearc">小项：</span>
					</div>
				</template>
			</el-select>
			<div class="but" @click="getxm2">
				<div class="img">
					<img src="../assets/image/searchfor.png" alt="">
				</div>
				<div class="text">搜索</div>
			</div>
		</div>

		<div class="table">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%;" :header-cell-style="{ background: '#0A6DF3' }">
				<el-table-column type="index" label="序号" align="center" width="55">
				</el-table-column>
				<el-table-column prop="projectName" label="项目" align="center">
				</el-table-column>
				<el-table-column prop="matchName" label="运动会名称" align="center">
				</el-table-column>
				<el-table-column prop="createTime" label="时间" align="center">
					<template slot-scope="scope">
						{{ scope.row.conferenceDate | format}}
					</template>
				</el-table-column>
				<el-table-column prop="matchPlace" label="地点" align="center">
				</el-table-column>
				<el-table-column prop="groupName" label="组别" align="center">
				</el-table-column>
				<el-table-column prop="subProject" label="小项" align="center">
				</el-table-column>
				<el-table-column prop="score" label="成绩" align="center">
				</el-table-column>
				<el-table-column prop="athleteName" label="创造者" align="center">
				</el-table-column>
				<el-table-column prop="statisticalYear" label="统计年份" align="center">
				</el-table-column>
				<el-table-column prop="remarks" label="备注" align="center">
				</el-table-column>
			</el-table>
			<div v-if="tableData.length == 0" style="margin-top: 10PX;">请选择项目</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10" :pageNo="info.pageNo"></pagination>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'notice',
		components: {},
		data() {
			return {
				options1: [],
				options2: [],
				options3: [],
				tableData: [],
				total: 0,
				info: {
					pageNo: 1,
					pageSize: 10,
					groupName:'',
					projectName:'',
					subProject:'',
				},
				loading:false
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.getMatchHighestScoreThreeLinkQuery()
		},
		methods: {
			getMatchHighestScoreThreeLinkQuery() {
				this.$api.getMatchHighestScoreThreeLinkQuery({dictValue:'其他'}).then(res => {
					this.options1 = res.data.data.result
				})
			},
			currentchange(val) {
				this.info.pageNo = val
				this.highestRecordGroup()
			},
			getxm(e,index){
				if(index == 1){
					this.options2 = []
					this.options3 = []
					this.info.groupName = ''
					this.info.subProject = ''
					this.options1.forEach(item=>{
						if(item.projectName == e){
							this.options2 = item.groupNames
						}
					})
				}
				if(index == 3){
					this.options3 = []
					this.info.subProject = ''
					this.options2.forEach(item=>{
						if(item.groupName == e){
							this.options3 = item.subProjectNames
						}
					})
				}
			},
			getxm2(){
				this.info.pageNo = 1
				this.highestRecordGroup()
			},
			highestRecordGroup() {
				this.loading = true
				this.$api.highestRecordGroup(this.info).then(res => {
					if(res.data.data.result.records == null) return
					res.data.data.result.records.forEach(item=>{
						item.athleteName = this.$decrypt(item.athleteName)
					})
					this.tableData = res.data.data.result.records
					this.total = res.data.data.result.total
					this.loading = false
				}).catch(error => {
					this.loading = false
				})
			},
		}
	}
</script>
<style  lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 20PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.input {
		margin-top: 28PX;
		height: 39PX;
	}

	.input ::v-deep .el-input__inner {
		height: 34PX;
		border: 2PX solid #164B92;
		height: 39PX;
		width: 160PX;
	}

	.input ::v-deep .el-input-group__append {
		border: 2PX solid #164B92;
		color: #fff;
		background-color: #164B92;
		width: 139PX;
		height: 39PX;
	}

	.input ::v-deep .el-input-group__append button.el-button {
		font-weight: bold;
	}

	.game {
		margin-top: 22PX;
	}

	.game p {
		position: absolute;
		margin-left: 163PX;
		font-size: 30PX;
		font-weight: 500;
		color: #164B92;
		margin-top: 38PX;
	}

	.searchfor {
		width: 1200PX;
		height: 123PX;
		background: rgba(216, 233, 255, 0.38);
		border-radius: 7PX;
		margin-top: 15PX;
		display: flex;
		justify-content: center;
	}

	.searchfor ::v-deep .el-input__inner {
		width: 250PX;
		height: 43PX;
		background: #FFFFFF;
		border: 1PX solid #003680;
		border-radius: 7PX;
		font-size: 18PX;
		color: #333333;
		padding: 0 15PX 0 120PX;
	}

	.searchfor ::v-deep input::-webkit-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep input::-moz-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep input::-ms-input-placeholder {
		color: #333333;
	}

	.searchfor ::v-deep .el-select .el-input .el-select__caret {
		color: #191919;
	}

	.searchfor .searchforlogo {
		display: block;
		width: 28PX;
		height: 27PX;
		margin-top: 8PX;
		margin-left: 17PX;
	}

	.searchfor .searchforlogo img {
		width: 100%;
		height: 100%;
	}

	.searchfor .titlesearc {
		font-size: 18PX;
		font-weight: bold;
		color: #333333;
		margin-top: 9PX;
		margin-left: 13PX;
	}

	.searchfor .inp ::v-deep .el-input__inner {
		width: 201PX;
		height: 43PX;
	}

	.project {
		margin-top: 41PX;
	}

	.level {
		margin-top: 41PX;
		margin-left: 66PX;
	}

	.area {
		margin-top: 41PX;
		margin-left: 66PX;
	}

	.but {
		width: 150PX;
		height: 43PX;
		background: #164B92;
		border-radius: 9PX;
		display: flex;
		align-items: center;
		margin-top: 41PX;
		margin-left: 29PX;
		cursor: pointer;
	}

	.but .img {
		width: 21PX;
		height: 21PX;
		margin-left: 31PX;
	}

	.but .text {
		font-size: 19PX;
		font-weight: bold;
		color: #FFFFFF;
		margin-left: 7PX;
		letter-spacing: 4PX;
	}

	.table {
		margin-top: 40PX;
		margin-bottom: 80PX;
	}

	.table ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
	}

	.table ::v-deep .el-table__header-wrapper {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table th.el-table__cell.is-leaf {
		border-bottom: 1PX solid #000;
		border-right: 1PX solid #000;
	}

	.table ::v-deep .el-table td,
	.el-table th.is-leaf,
	.el-table--border,
	.el-table--group {
		border-color: black;
	}

	.table ::v-deep .el-table--border::after,
	.el-table--group::after,
	.el-table::before {
		background-color: black;
	}

	.el-select-dropdown__item {
		font-size: 18PX;
	}

	.paging {
		margin-top: 18PX;
		margin-bottom: 104PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}
</style>